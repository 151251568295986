import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Col, Offcanvas, Row } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import "./Navbar.css";
import { FaAngleDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiWechatFill } from "react-icons/ri";
import { store } from "../Context/store";
import NavbarImage from "../../assets/img/featured-image-1.png";
import KazaMatrixLogo from "../../assets/img/logo-kazamatrix.png";
import GrubDigestLogo from "../../assets/img/logo-grubdigest.png";
import BechofyLogo from "../../assets/img/logo-bechofy.png";
import { useNavigate } from "react-router-dom";

function NavbarMenu() {
  const [show, setShow] = useState(false);
  const [isMegaMenuShown, setIsMegaMenuShown] = useState("no-popup");

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const [scroll, setScroll] = useState(false);
  const [scrollToTopBtn, setScrollToTopBtn] = useState(false);
  const { activeLink, setactiveLink } = useContext(store);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 1000) {
      setScrollToTopBtn("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScrollToTopBtn(window.scrollY > 1000);
      });
    }
  }, []);

  const navigate = useNavigate();
  const handleAboutScroll = () => {
    navigate("/");
    setTimeout(() => {
      const sectionToScroll = document.getElementById("aboutUs");
      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({ behavior: "smooth" });
      }
    }, "100");
  };

  return (
    <div className="custom-nav">
      <Navbar
        expand="xl"
        className={
          scroll
            ? "bg-dark shadow-sm fixed-top ms-lg-auto px-0 py-0"
            : "ms-lg-auto px-0 py-0 fixed-top"
        }
      >
        <Container fluid className="px-lg-5">
          <div className="w-100 py-lg-2 d-flex justify-content-between align-items-center border-lg-bottom">
            <Navbar.Brand>
              <a
                href="/"
                className={
                  activeLink == "Home" ? "logo-box active" : "logo-box"
                }
                onClick={() => {
                  setactiveLink("Home");
                }}
              >
                <img src={Logo} className="logo" alt="global trendz logo" />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle className="px-0 border-0 outline-0" />
            <Navbar.Offcanvas placement="end" className="bg-dark mobile-menu">
              <Offcanvas.Header closeButton className="pb-0">
                <Offcanvas.Title className="display-4 p-2 text-white opacity-50 fw-normal">
                  {/* Menu */}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="justify-content-center align-items-center pt-0">
                <Nav className="justify-content-end align-items-xl-center flex-grow-1">
                  {/* <a
                    className={
                      activeLink == "Home" ? "nav-link active" : "nav-link"
                    }
                    href="/"
                    onClick={() => {
                      setactiveLink("Home");
                    }}
                  >
                    home
                  </a> */}
                  <a
                    // className={
                    //   activeLink == "About" ? "nav-link active" : "nav-link"
                    // }
                    className="nav-link"
                    onClick={handleAboutScroll}
                  >
                    about us
                  </a>
                  <a
                    className={
                      activeLink == "Services" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      setactiveLink("Services");
                    }}
                    onMouseEnter={() => {
                      if (
                        isMegaMenuShown === "products" ||
                        isMegaMenuShown === "no-popup"
                      ) {
                        setIsMegaMenuShown("services");
                      } else {
                        setIsMegaMenuShown("no-popup");
                      }
                    }}
                  >
                    Services <FaAngleDown />
                  </a>
                  <a
                    className={
                      activeLink == "Products"
                        ? "nav-link active nav-link-product"
                        : "nav-link nav-link-product"
                    }
                    onClick={() => {
                      setactiveLink("Products");
                    }}
                    onMouseEnter={() => {
                      if (
                        isMegaMenuShown === "services" ||
                        isMegaMenuShown === "no-popup"
                      ) {
                        setIsMegaMenuShown("products");
                      } else {
                        setIsMegaMenuShown("no-popup");
                      }
                    }}
                  >
                    Products <FaAngleDown />
                  </a>
                  <a
                    className={
                      activeLink == "Technologies"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="/technologies"
                    onClick={() => {
                      setactiveLink("Technologies");
                    }}
                  >
                    Technologies
                  </a>
                  <a
                    className={
                      activeLink == "WorkWithUs"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="/work-with-us"
                    onClick={() => {
                      setactiveLink("WorkWithUs");
                    }}
                  >
                    work with us
                  </a>
                  {/* <a
                    className={
                      activeLink == "Faq" ? "nav-link active" : "nav-link"
                    }
                    href="/faq"
                    onClick={() => {
                      setactiveLink("Faq");
                    }}
                  >
                    FAQs
                  </a> */}
                  {/* <a
                    className={
                      activeLink == "News" ? "nav-link active" : "nav-link"
                    }
                    href="/news"
                    onClick={() => {
                      setactiveLink("News");
                    }}
                  >
                    news & blog
                  </a> */}
                  {/* <a
                    className={
                      activeLink == "CaseStudies"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="/case-studies"
                    onClick={() => {
                      setactiveLink("CaseStudies");
                    }}
                  >
                    case studies
                  </a> */}
                  <a
                    className={
                      activeLink == "ContactUs" ? "nav-link active" : "nav-link"
                    }
                    href="/contact-us"
                    onClick={() => {
                      setactiveLink("ContactUs");
                    }}
                  >
                    contact us
                  </a>
                  <a
                    href="tel:+919821900322"
                    className="btn-talk-to-us text-dedcoration-none"
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <RiWechatFill className="talk-to-us-icon" />
                      </div>
                      <div className="talk-to-us">
                        <p>talk to us</p>
                        {/* <p>+91-79828 28789</p> */}
                        <p>+91 98219 00322</p>
                      </div>
                    </div>
                  </a>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Container>
      </Navbar>
      <div
        className="mega-menu"
        onMouseEnter={() => setIsMegaMenuShown("services")}
        onMouseLeave={() => setIsMegaMenuShown("no-popup")}
        style={{ display: isMegaMenuShown === "services" ? "block" : "none" }}
      >
        <Container fluid>
          <Row className="flex-column flex-lg-row">
            <span
              className="close-arrow"
              onClick={() => setIsMegaMenuShown("no-popup")}
            >
              <FaChevronLeft />
            </span>
            <Col className="px-0">
              <img
                src={NavbarImage}
                className="navbar-img"
                alt="global trendz services"
              />
            </Col>
            <Col className="nav-list first">
              <p className="fst-italic mb-2">In short we look at</p>
              <h2 className="highlight">Bridging Technology & Business</h2>
              <p className="mb-2">
                We're dedicated to find the best possible solution to every
                challenge, as well as a genuine passion to deliver with
                excellence.
              </p>
              <p>Take your Business to the Next Level.</p>
              <a href="tel:+919821900322" className="btn btn-primary talk">
                Let's Talk
              </a>
            </Col>
            <Col className="nav-list">
              <h5 className="fw-bold">Information Technology</h5>
              <ul className="mega-menu-list mb-5">
                <li>
                  <a href="/software-development">
                    <FaChevronRight /> Software Development
                  </a>
                </li>
                <li>
                  <a href="/it-consulting">
                    <FaChevronRight /> IT Consulting
                  </a>
                </li>
                <li>
                  <a href="/oracle-consulting">
                    <FaChevronRight /> Oracle Consulting
                  </a>
                </li>
                <li>
                  <a href="/it-outsource">
                    <FaChevronRight /> IT Outsource
                  </a>
                </li>
                <li>
                  <a href="/dedicated-resource">
                    <FaChevronRight /> Dedicated Resource
                  </a>
                </li>
              </ul>
              <h5 className="fw-bold">e-Commerce</h5>
              <ul className="mega-menu-list">
                <li>
                  <a href="/ecommerce-consulting">
                    <FaChevronRight /> e-Commerce Consulting
                  </a>
                </li>
                <li>
                  <a href="/ecommerce-implementation">
                    <FaChevronRight /> e-Commerce Implementation
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="nav-list">
              <h5 className="fw-bold">Web &amp; Mobile</h5>
              <ul className="mega-menu-list">
                <li>
                  <a href="/web-application-development">
                    <FaChevronRight /> Web Application Development
                  </a>
                </li>
                <li>
                  <a href="/website-design-and-development">
                    <FaChevronRight /> Website Design & Development
                  </a>
                </li>
                <li>
                  <a href="/ecatalogues-and-ebrochures">
                    <FaChevronRight /> eCatalogues &amp; eBrochures
                  </a>
                </li>
                <li>
                  <a href="/mobile-application-development">
                    <FaChevronRight /> Mobile Application Development
                  </a>
                </li>
                <li>
                  <a href="/progressive-web-applications">
                    <FaChevronRight /> Progressive Web Applications
                  </a>
                </li>
                <li>
                  <a href="/ui-and-ux-design">
                    <FaChevronRight /> UI & UX Design
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="nav-list">
              <h5 className="fw-bold">Digital Marketing</h5>
              <ul className="mega-menu-list mb-5 mb-md-0 pb-5 pb-md-0">
                <li>
                  <a href="/search-engine-optimization">
                    <FaChevronRight /> Search Engine Optimization (SEO)
                  </a>
                </li>
                <li>
                  <a href="/search-engine-marketing">
                    <FaChevronRight /> Search Engine Marketing (SEM)
                  </a>
                </li>
                <li>
                  <a href="/social-media-marketing">
                    <FaChevronRight /> Social Media Marketing (SMM)
                  </a>
                </li>
                <li>
                  <a href="/digital-media-advertising">
                    <FaChevronRight /> Digital Media Advertising
                  </a>
                </li>
                <li>
                  <a href="/lead-generation">
                    <FaChevronRight /> Lead Generation
                  </a>
                </li>
                <li>
                  <a href="/content-writing">
                    <FaChevronRight /> Content Writing
                  </a>
                </li>
                <li>
                  <a href="/graphic-designing">
                    <FaChevronRight /> Graphic Designing
                  </a>
                </li>
                {/* <li>
                  <a href={`/digital-marketing/${"blogs".replace(/ /g, "-")}`}>
                    <FaChevronRight /> Blogs
                  </a>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        onMouseEnter={() => setIsMegaMenuShown("products")}
        onMouseLeave={() => setIsMegaMenuShown("no-popup")}
        className="mega-menu"
        style={{ display: isMegaMenuShown === "products" ? "block" : "none" }}
      >
        <Container fluid>
          <Row className="flex-column flex-lg-row">
            <span
              className="close-arrow"
              onClick={() => setIsMegaMenuShown("no-popup")}
            >
              <FaChevronLeft />
            </span>
            <Col className="px-0">
              <img
                src={NavbarImage}
                className="navbar-img"
                alt="global trendz products"
              />
            </Col>
            <Col className="nav-list first">
              <p className="fst-italic mb-2">In short we look at</p>
              <h2 className="highlight">
                Empowering Your Business With Technology
              </h2>
              <p className="mb-2">
                Our Products Cater to a wide range of Industry Verticals in
                helping you to add the power of technology to enhance your
                business.
              </p>
              <p>Take your Business to the Next Level.</p>
              <a href="tel:+919821900322" className="btn btn-primary talk">
                Let's Talk
              </a>
            </Col>
            <Col className="nav-list pt-4">
              <a
                href="https://bechofy.in/"
                className="website-link"
                target="_blank"
              >
                <img
                  src={BechofyLogo}
                  className="img-fluid prod-img"
                  alt="Bachofy - A Gateway for online selling"
                />
              </a>
              <p className="mt-4 mb-2 fw-bold">
                Take your Existing Business or Startup Online
              </p>
              <p className="prod-des mb-0">
                Selling online with Bechofy e-Commerce enables you to get
                profitable results
              </p>
              <p className="pt-lg-2 mb-0">
                <a
                  href="https://bechofy.in/"
                  className="website-link"
                  target="_blank"
                >
                  www.bechofy.in
                </a>
              </p>
            </Col>
            <Col className="nav-list pt-4">
              <a
                href="https://www.grubdigest.com/"
                className="website-link"
                target="_blank"
              >
                <img
                  src={GrubDigestLogo}
                  className="img-fluid prod-img"
                  alt="Grub Digest - Bring your restaurant and food delivery business online."
                />
              </a>
              <p className="mt-4 mb-2 fw-bold">
                Take your Restaurant & Food Delivery Business Online
              </p>
              <p className="prod-des mb-0">
                Use the Grub Digest Online Food Ordering Platform to feed your
                customers using your own website.
              </p>
              <p className="pt-lg-2 mb-0">
                <a
                  href="https://www.grubdigest.com/"
                  className="website-link"
                  target="_blank"
                >
                  www.grubdigest.com
                </a>
              </p>
            </Col>
            <Col className="nav-list pt-4">
              <a
                href="http://www.kazamatrix.com/"
                className="website-link"
                target="_blank"
              >
                <img
                  src={KazaMatrixLogo}
                  className="img-fluid prod-img"
                  alt="KAZA MATRIX - All About Faces"
                />
              </a>
              <p className="mt-4 mb-2 fw-bold">
                Revolutionzing Event Check-in Experience
              </p>
              <p className="prod-des mb-0">
                A full fledged Event Check-in solution catering to Trade Show
                Organisers, Event Managers etc.
              </p>
              <p className="pt-lg-2 mb-0">
                <a
                  href="http://www.kazamatrix.com/"
                  className="website-link"
                  target="_blank"
                >
                  www.kazamatrix.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default NavbarMenu;
