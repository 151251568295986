import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import PageBanner from "../../../components/PageBanner/PageBanner";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./SoftwareDevelopment.css";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import SoftwareDevelopmentImg from "../../../assets/img/software-development-page-image.png";
import SoftwareDevelopmentPageBanner from "../../../assets/img/services-banner/Softwar-Development.png";
import data from "../../../data.json";
import CommonContactSection from "../CommonContactSection/CommonContactSection";
export default function SoftwareDevelopment() {
  const [pageData, setPageData] = useState({});
  const [currPage, setCurrPage] = useState("");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setPageData({});
    setPageData(
      data[0][
        window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        )
      ]
    );
    setCurrPage(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
  });
  return (
    <>
      <Navbar />
      <PageBanner pageData={pageData} bgImage={SoftwareDevelopmentPageBanner} />
      <section>
        <Container className="position-relative py-lg-5 py-3">
          <Row className="align-items-center">
            <Col lg={12} className="text-center">
              <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.heading}
              </h2>

              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.subHeading}
              </p>

              <p className="section-highlighter wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.description}
              </p>
            </Col>
          </Row>
        </Container>
        {/* <div className="bg-light text-dark">
          <Row className="align-items-center justify-content-end position-relative m-0 p-0">
            <Col lg={6} className="p-0 m-0">
              <div className="float-image"></div>
            </Col>
            <Col lg={6} className="p-3 p-lg-5 text-lg-end text-center">
              <h2 className="main-title mb-4 wow animate__animated animate__fadeInUp">
                {pageData.whyChooseSectionTitle}
              </h2>
              <p className="main-text wow animate__animated animate__fadeInUp text-lg-end text-center">
                {pageData.whyChooseSectionTitleDescription}
              </p>
            </Col>
          </Row>
        </div> */}
        <div
          className={
            pageData.whyChooseSectionImage
              ? "oracle-consulting-section position-relative"
              : "oracle-consulting-section position-relative"
          }
        >
          <Container
            className={pageData.whyChooseSectionImage ? "pt-5" : "pt-0"}
          >
            <Row>
              <Col lg={12}>
                <div className="mb-4 text-center">
                  <h2 className="main-title  main-title-3 wow animate__animated animate__fadeInUp">
                    {pageData.CardSectionOne?.heading}
                  </h2>
                  <p className="main-text wow text-center animate__animated animate__fadeInUp ">
                    {pageData.CardSectionOne?.description}
                  </p>
                </div>
                {/* <Row className="oracle-consul-slider-main">
                  {pageData.CardSectionOne?.cards ? (
                    <MultiCarousel
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      infinite={true}
                      className="pb-5"
                    >
                      {pageData.CardSectionOne?.cards?.map((card) => {
                        return (
                          <div className="carousel-card">
                            <div className="carousel-card-img">
                              <img src={card.img} className="img-fluid" />
                            </div>

                            <div className="carousel-card-content">
                              <h3>{card.heading}</h3>
                              <p>{card.description}</p>
                            </div>
                          </div>
                        );
                      })}
                    </MultiCarousel>
                  ) : (
                    <></>
                  )}
                </Row> */}
              </Col>
            </Row>
            <Row className="py-lg-3 py-4">
              {pageData.CardSectionOne?.cards?.map((card) => {
                return (
                  <Col lg={4} className="mb-4">
                    <div className="carousel-card">
                      <div className="carousel-card-content">
                        <img className="carousel-card-icon" src={card.icon} />
                        <h3>{card.heading}</h3>
                        <p>{card.description}</p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <Container
          style={{ display: pageData.CardSectionTwo ? "block" : "none" }}
        >
          <Row>
            <Col lg={12}>
              <h2 className="main-title wow animate__animated animate__fadeInUp text-center mb-4">
                {pageData.CardSectionTwo?.heading}
              </h2>
              <p className="main-text wow text-center animate__animated animate__fadeInUp">
                {pageData.CardSectionTwo?.description}
              </p>
              <Row>
                {pageData.CardSectionTwo?.cards?.map((card) => {
                  return (
                    <Col lg={4} className="mb-5">
                      <div className="carousel-card">
                        <div className="carousel-card-content">
                          <h3>{card.heading}</h3>
                          <p>{card.description}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Accordion Item #1</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
        <div
          className="bg-white why-us mt-4"
          style={{
            display: pageData.parallexSectionHeading ? "block" : "none",
          }}
        >
          <Container>
            <Row>
              <Col lg={12} className="text-center text-white position-relative">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  {pageData.parallexSectionHeading}
                </h2>
                {/* </Col> */}
                <div>
                  <p className="section-highlighter wow animate__animated animate__fadeInUp mb-0">
                    {pageData.parallexSectionDescription}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          style={{ display: pageData.contactSectionTitle ? "block" : "none" }}
        >
          <Row>
            <Col lg={12}>
              <div className="contact-us-inner d-lg-flex align-items-lg-center">
                <div className="contact-us-inner-text">
                  <h2 className="main-title wow animate__animated animate__fadeInUp">
                    {pageData.contactSectionTitle}
                  </h2>

                  <p className="main-text wow animate__animated animate__fadeInUp">
                    {pageData.contactSectionTitleDescription}
                  </p>
                </div>
                <div className="contact-us-inner-btn text-lg-center">
                  <Link
                    to="/contact-us"
                    className="btn btn-primary talk mt-5px"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <CommonContactSection />
      <Footer />
    </>
  );
}
