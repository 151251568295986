import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import {
  AiOutlineGitlab,
  AiOutlineGlobal,
  AiOutlineMeh,
  AiOutlineRadarChart,
  AiOutlineTeam,
} from "react-icons/ai";
import { SiOracle } from "react-icons/si";
import DedicatedResources from "../../assets/img/home-it/Dedicated-Resources.png";
import ItConsultancyServices from "../../assets/img/home-it/IT-Consultancy-Services.png";
import ItOutsource from "../../assets/img/home-it/IT-Outsource.png";
import Oracleconsulting from "../../assets/img/home-it/Oracle-consulting.png";
import SoftwareDevelopement from "../../assets/img/home-it/Software-Developement.png";
import { FiChevronUp } from "react-icons/fi";
import { MdDoubleArrow } from "react-icons/md";

function InformationTechnology() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="information-technology-section py-lg-5 pb-4 pt-5 bg-white">
      <Container className="position-relative">
        <Row className="align-items-start justify-content-center">
          <Col lg={10}>
            <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp">
              Information Technology Solutions
            </h2>
            <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
              Information Technology Solutions Streamlining your business with
              the latest technology is the need of the hour.
            </p>
            <p className="main-text text-center wow animate__animated animate__fadeInUp">
              We are dedicated to ensuring that our clients receive the right
              solutions. Our robust and qualified team takes the time to
              understand and analyze your requirements before arriving at a
              suitable solution.
            </p>
          </Col>
          <MultiCarousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={false}
          >
            <div className="technology-card text-center">
              <div className="technology-card-inner">
                <img
                  src={SoftwareDevelopement}
                  className="img-fluid service-visual"
                  alt="Software Development"
                />
                <p className="fst-italic mb-0">Providing Robust Solutions</p>
                <h3 className="how-it-works-card__title">
                  <a href="#">Software Development</a>
                </h3>
                <p className="how-it-works-card__text">
                  Elevate your business through cutting-edge software
                  development. Our process, which includes conceiving,
                  specifying, designing, programming, testing, documenting, and
                  bug fixing, guarantees enduring and effective results.
                </p>
                <a
                  href={`${"software development".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </div>
            <div className="technology-card text-center">
              <div className="technology-card-inner">
                <img
                  src={ItConsultancyServices}
                  className="img-fluid service-visual"
                  alt="IT Consulting Service"
                />
                <p className="fst-italic mb-0">Revamp Your IT Environment</p>
                <h3 className="how-it-works-card__title">
                  <a href="#">IT Consultancy Services</a>
                </h3>
                <p className="how-it-works-card__text">
                  Navigate the complexities of your business with our IT
                  consultancy services. We comprehend your existing processes
                  and deliver a tailored, cost-effective information
                  technology-driven expansion process. This encompasses project
                  scoping...
                </p>
                <a
                  href={`${"it consulting".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </div>
            <div className="technology-card text-center">
              <div className="technology-card-inner">
                <img
                  src={Oracleconsulting}
                  className="img-fluid service-visual"
                  alt="Oracle Consulting Service"
                />
                <p className="fst-italic mb-0">
                  Elevating Your Business Performance
                </p>
                <h3 className="how-it-works-card__title">
                  <a href="#">Dedicated Resource</a>
                </h3>
                <p className="how-it-works-card__text">
                  Maximize your work quality with our dedicated resource
                  solutions. we understand the challenges businesses face when
                  it comes to finding the right talent to drive their projects
                  forward. That's why we offer dedicated resource services to
                  help you access...
                </p>
                <a
                  href={`${"dedicated resource".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </div>
            <div className="technology-card text-center">
              <div className="technology-card-inner">
                <img
                  src={ItOutsource}
                  className="img-fluid service-visual"
                  alt="IT Outsource"
                />
                <p className="fst-italic mb-0">Enhancing Your IT Solutions</p>
                <h3 className="how-it-works-card__title">
                  <a href="#">IT Outsource</a>
                </h3>
                <p className="how-it-works-card__text">
                  Optimize your operations by outsourcing IT tasks to us. We
                  understand your existing processes and offer a suitable,
                  cost-effective information technology-driven expansion
                  process. Our comprehensive approach includes project
                  scoping...
                </p>
                <a
                  href={`${"it outsource".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </div>
            <div className="technology-card text-center">
              <div className="technology-card-inner">
                <img
                  src={DedicatedResources}
                  className="img-fluid service-visual"
                  alt="Dedicated Resource"
                />
                <p className="fst-italic mb-0">
                  Optimizing Oracle Capabilities
                </p>
                <h3 className="how-it-works-card__title">
                  <a href="#">Oracle Consulting</a>
                </h3>
                <p className="how-it-works-card__text">
                  Unleash the full potential of Oracle with our consulting
                  services. We comprehend your existing processes and deliver a
                  tailored, affordable information technology-driven expansion
                  process. This includes meticulous project scoping...
                </p>
                <a
                  href={`${"oracle consulting".replace(/ /g, "-")}`}
                  className="service-read-more"
                >
                  <MdDoubleArrow />
                </a>
              </div>
            </div>
          </MultiCarousel>
        </Row>
      </Container>
    </section>
  );
}

export default InformationTechnology;
