import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import "animate.css";

import About from "../Home/About";
import CarouselNew from "../Home/Carousel";
import Services from "../Home/Services";
import Products from "../Home/Products";
import WhyUs from "../Home/WhyUs";
import InformationTechnology from "../Home/InformationTechnology";
import ECommerce from "../Home/eCommerceServices";
import WebsiteDevelopment from "../Home/WebsiteDevelopment";
import GlobalWebsite from "./GlobalWebsite";

function Home() {
  return (
    <>
      <Navbar />
      <CarouselNew />
      <About />
      <WhyUs />
      <InformationTechnology />
      <ECommerce />
      <GlobalWebsite />
      <WebsiteDevelopment />
      <Services />
      <Products />
      <Footer />
    </>
  );
}

export default Home;
