import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./PageBanner.css";

function About(props) {
  let location = useLocation();
  // useEffect(() => {
  //   console.log(
  //     String(location.pathname)
  //       .replace("/", "")
  //       .replace("-", " ")
  //       .replace("-", " ")
  //   );
  // }, []);
  return (
    <>
      <section className="page-banner">
        <div
          className="image-layer h-100"
          style={{
            backgroundImage: `url("${
              props?.bgImage != undefined
                ? props?.bgImage
                : props.pageData?.bgImageBanner
            }")`,
          }}
        ></div>

        <div className="banner-inner h-100">
          <div className="container h-100">
            <div className="inner-container clearfix">
              <h1>
                {props.pageData != null
                  ? props.pageData.title
                  : String(location.pathname)
                      .replace("/", "")
                      .replace("-", " ")
                      .replace("-", " ")}
              </h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li className="thm-breadcrumb__item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="thm-breadcrumb__item active">
                    <span>
                      {props.pageData != null
                        ? props.pageData.title
                        : String(location.pathname)
                            .replace("/", "")
                            .replace("-", " ")
                            .replace("-", " ")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
