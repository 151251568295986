
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DigitalCarousel from "./DigitalCarousel"
    ;
import DigitalServices from "./DigitalServices";
import { Link } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import DigitalForm from "./Form";
export default function DigitalMarketing() {
    return (
        <>
            <Navbar />
            <DigitalCarousel />
            <div className="container d-block d-lg-none mt-3">
                <div className="row">
                    <div className="col-12">
                        <DigitalForm />
                    </div>
                </div>
            </div>
            <DigitalServices />
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="contact-us-inner d-lg-flex align-items-lg-center justify-content-lg-center">
                            <div className="contact-us-inner-text px-0  text-center w-100">
                                <h2 className="main-title wow animate__animated animate__fadeInUp fs-1">
                                    For best deals call <br className="d-block d-lg-none" /> <a href="tel:+919821900322" className="text-dark">+91 98219 00322</a>
                                </h2>
                            </div>
                            {/* <div className="contact-us-inner-btn align-items-center justify-content-lg-end pt-lg-3">
                                <a href="tel:+919821900322" className="btn btn-primary talk fs-5 px-3 py-2">
                                    Call :
                                    <b className="ms-1">+91 98219 00322</b>
                                </a>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}