import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import PageBanner from "../../../components/PageBanner/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import "./EcommerceImpimplementation.css";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import SoftwareDevelopmentImg from "../../../assets/img/software-development-page-image.png";
import HeroBanner from "../../../assets/img/services-banner/eCommerce-Implementation.png";
import BenefitsImage from "../../../assets/img/ecommerce-implementation.jpg";
import BenefitsImageBottom from "../../../assets/img/ecommerce-consulting.jpg";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import data from "../../../data.json";
import CommonContactSection from "../CommonContactSection/CommonContactSection";
export default function EcommerceImpimplementation() {
  const [pageData, setPageData] = useState({});
  const [currPage, setCurrPage] = useState("");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setPageData({});
    setPageData(
      data[0][
        window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        )
      ]
    );
    setCurrPage(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
  });
  return (
    <>
      <Navbar />
      <PageBanner pageData={pageData} bgImage={HeroBanner} />
      <section className="pt-lg-5 pt-3">
        <Container className="position-relative">
          <Row className="align-items-center">
            <Col lg={12} className="text-center">
              <h2 className="main-title text-center main-title-3 wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.heading}
              </h2>

              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.subHeading}
              </p>

              <p className="section-highlighter wow animate__animated animate__fadeInUp">
                {pageData.pageHeader?.description}
              </p>
            </Col>
          </Row>
        </Container>
        <div
          className={
            pageData.whyChooseSectionImage
              ? "oracle-consulting-section position-relative mt-4"
              : "oracle-consulting-section position-relative mt-4"
          }
        >
          <Container
          // className={pageData.whyChooseSectionImage ? "pt-5" : "pt-0"}
          >
            <Row>
              <Col lg={12}>
                <div className="mb-4 text-center">
                  <h2 className="main-title  main-title-3 wow animate__animated animate__fadeInUp">
                    {pageData.CardSectionOne?.heading}
                  </h2>
                  <p className="main-text wow text-center animate__animated animate__fadeInUp ">
                    {pageData.CardSectionOne?.description}
                  </p>
                </div>
                <Row className="align-items-center mb-5">
                  <Col lg={6} className=" mb-3">
                    <img
                      src={BenefitsImage}
                      className="img-fluid rounded"
                      alt="E-commerce Implemention"
                    />
                  </Col>
                  <Col lg={6}>
                    <Row className="">
                      {pageData.CardSectionOne?.cards?.map((card) => {
                        return (
                          <Col lg={12} className="mb-2">
                            <div className="d-flex gap-2 align-items-center">
                              <div>
                                <IoCheckmarkCircleOutline className="check-icon" />
                              </div>
                              <div className="card p-3 shadow-sm">
                                <h5 className="fw-bold">{card.heading}</h5>
                                <p className="mb-0">{card.description}</p>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          style={{ display: pageData.CardSectionTwo ? "block" : "none" }}
        >
          <Row>
            <Col lg={12}>
              <h2 className="main-title wow animate__animated animate__fadeInUp text-center mb-4">
                {pageData.CardSectionTwo?.heading}
              </h2>
              <p className="main-text wow text-center animate__animated animate__fadeInUp">
                {pageData.CardSectionTwo?.description}
              </p>
              <Row className="align-items-center mb-5">
                <Col lg={6} className="order-lg-1 order-2">
                  <Row className="">
                    {pageData.CardSectionTwo?.cards?.map((card) => {
                      return (
                        <Col lg={12} className="mb-2">
                          <div className="d-flex gap-2 align-items-center">
                            <div>
                              <IoCheckmarkCircleOutline className="check-icon" />
                            </div>
                            <div className="card p-3 shadow-sm">
                              <h5 className="fw-bold">{card.heading}</h5>
                              <p className="mb-0">{card.description}</p>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg={6} className="order-lg-2 order-1 mb-3">
                  <img
                    src={BenefitsImageBottom}
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div
          className="bg-white why-us mt-4"
          style={{
            display: pageData.parallexSectionHeading ? "block" : "none",
          }}
        >
          <Container>
            <Row>
              <Col lg={12} className="text-center text-white position-relative">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  {pageData.parallexSectionHeading}
                </h2>
                {/* </Col> */}
                <div>
                  <p className="section-highlighter wow animate__animated animate__fadeInUp mb-0">
                    {pageData.parallexSectionDescription}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <CommonContactSection />
      <Footer />
    </>
  );
}
