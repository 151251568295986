import React, { useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";

function GlobalWebsite() {
  return (
    <section className="bg-white why-us">
      <Container className="position-relative">
        <Row className="align-items-center justify-content-center">
          <Col lg={10} className="text-center text-white">
            <h2 className="main-title mb-lg-5">
              Get a Professionally Designed Website or Mobile Application
            </h2>
            <p className="main-text text-center">
              <span className="fw-bold fst-italic">Have a business idea? </span>
              Let's work with you to integrate technology into your idea and
              build an aesthetically designed and developed website or mobile
              application.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default GlobalWebsite;
